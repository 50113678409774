import React from 'react';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import Archive from '../archive';
import styles from './app-root.scss';

const AppRoot: React.FC = () => {
  return (
    <ResponsiveListener dataHook="archive-widget-root" className={styles.baseStyles}>
      <Archive />
      <AppLoaded />
    </ResponsiveListener>
  );
};

export default withReduxStore(AppRoot);
